<script context="module">
  const graphOptions = {
    layout: {
      labelString: ' TH/s',
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 20,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function (value, index, values) {
              return value + ' TH/s';
            },
            min: 0,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ' + tooltipItem.yLabel + ' TH/s';
          }
          return label;
        },
      },
    },
  };
</script>

<script>
  import LineChart from 'svelte-chartjs/src/Line.svelte';
  export let graph;
</script>

<div class="chart-container">
  <LineChart data={graph} options={graphOptions} />
</div>

<style>
  @media (min-width: 768px) {
    .chart-container {
      height: 300px;
    }
  }

  .chart-container {
    height: 550px;
  }</style>
