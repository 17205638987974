<script>
  import dayjs from 'dayjs';
  import { getContext, onDestroy, onMount } from 'svelte';

  import Table from '../../components/Table.svelte';
  import CalendarModal from '../../components/CalendarModal.svelte';
  import PpsByDate from './_componets/PpsByDate.svelte';

  import { extra, extra as extrasStore } from '../../stores';
  import request, { defaultRequestState } from '../../utils/request';
  import Loading from '../../components/Loading.svelte';
  import Error from '../../components/Error.svelte';

  const { open } = getContext('MINERIUM_MODAL');

  let requestState = { ...defaultRequestState },
    coinsRequestState = { ...defaultRequestState },
    display = [];

  const updateDisplay = data => {
    display = data
      .reduce((tmp, pps) => {
        const datePPS = tmp.find(datePPS => datePPS.date === pps.date);
        if (datePPS) {
          datePPS.items.push(pps);
        } else {
          tmp.push({ date: pps.date, items: [pps] });
        }

        return tmp;
      }, [])
      .map(datePPS =>
        datePPS.items.some(pps => pps.coin === 'all')
          ? datePPS
          : {
              ...datePPS,
              items: [{ coin: 'all', price: '----' }, ...datePPS.items],
            },
      )
      .sort((a, b) => (a.date < b.date ? 1 : -1));
  };

  const getPPSPrices = async () => {
    requestState = { ...defaultRequestState };
    await request.get(
      '/earnings/pps-prices',
      data => (requestState = { ...requestState, ...data }),
    );
  };

  const fetchCoins = async () => {
    if ($extrasStore.coins) {
      return;
    }

    await request.get(
      '/pool/coins',
      data => (coinsRequestState = { ...coinsRequestState, ...data }),
    );

    if (coinsRequestState.failed) {
      extrasStore.update(data => ({ ...data, coins: null }));
      return;
    }

    extrasStore.update(data => ({
      ...data,
      coins: coinsRequestState.data,
    }));
  };

  const openPpsByDate = (date, delay = 0) => {
    const formattedDate = parseInt(date.format('YYYYMMDD'));
    const ppsByDate = requestState.data.reduce((tmp, pps) => {
      if (pps.date !== formattedDate) {
        return tmp;
      }

      tmp[pps.coin] = pps;
      return tmp;
    }, {});

    setTimeout(
      () =>
        open(
          PpsByDate,
          {
            title: date.format('dddd, MM-DD-YYYY'),
            ppsByDate,
            date,
          },
          { onClose: getPPSPrices },
        ),
      delay,
    );
  };

  const openPpsAddCalendar = () => {
    open(
      CalendarModal,
      { title: 'Create New PPS Entry' },
      {
        onClose: date => {
          openPpsByDate(date, 200);
        },
      },
    );
  };

  $: {
    getPPSPrices();
    fetchCoins();
  }

  $: if (requestState.success) updateDisplay(requestState.data);
  $: $extra.pageTitle = 'admin';
</script>

<div class="section">
  <header>
    <h2 class="dashboard">PPS Prices</h2>
  </header>
  <div class="control-bar">
    <fieldset class="btn-group">
      <button class="btn-modal" on:click={openPpsAddCalendar}>
        Create New PPS Entry
      </button>
    </fieldset>
  </div>
  {#if requestState.failed}
    <Error>
      {requestState.error || 'There was a error processing your request. Please try later.'}
    </Error>
    <div class="body">
      <p class="no-data xlarge">No Data</p>
    </div>
  {:else}
    <div class="body">
      {#if requestState.success}
        <Table items={display} key="date" tbodyClass="pps-prices">
          <tr slot="thead">
            <th class="desc">Date</th>
            <th>Prices</th>
          </tr>
          <tr slot="tbody" let:item>
            <td>
              <a
                href="#date"
                on:click|preventDefault={openPpsByDate(dayjs(item.date.toString(), 'YYYYMMDD'))}
                class="btn-modal"
                data-id="modal-sat03202021">
                {dayjs(item.date.toString(), 'YYYYMMDD').format('dddd')}
                <span>{dayjs(item.date.toString(), 'YYYYMMDD').format('MM-DD-YYYY')}</span>
              </a>
            </td>
            <td>
              <div class="price-list">
                {#each item.items.sort((a, b) =>
                  a.coin < b.coin ? -1 : 1,
                ) as pps (pps.id)}
                  <p>
                    {pps.coin === 'all' ? 'Default' : pps.coin.toUpperCase()}
                    <i>{pps.price}</i>
                  </p>
                {/each}
              </div>
            </td>
          </tr>
        </Table>
      {:else if requestState.sending}
        <Loading />
      {/if}
    </div>
  {/if}
</div>

<style>
  :global(.sc-popover) :global(.contents-wrapper) {
    z-index: 100000;
  }</style>
