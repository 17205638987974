<script context="module">
  const graphTypes = { hour: 'hour', day: 'day' };

  const initialGraphData = {
    labels: [],
    datasets: [
      {
        label: 'Hashrate ',
        fill: true,
        lineTension: 0.4,
        backgroundColor: 'rgba(4, 49, 128, 0.4)',
        borderColor: 'rgba(4, 49, 128, 0.75)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(4, 49, 128, 0.75)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(4, 49, 128, 0.75)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 20,
        data: [],
      },
    ],
  };

  const graphOptions = {
    layout: {
      labelString: ' TH/s',
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 20,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: function (value, index, values) {
              return value + ' TH/s';
            },
            min: 0,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label += ': ' + tooltipItem.yLabel + ' TH/s';
          }
          return label;
        },
      },
    },
  };
</script>

<script>
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import LineChart from 'svelte-chartjs/src/Line.svelte';

  import DataCard from '../../../../components/DataCard.svelte';
  import Loading from '../../../../components/Loading.svelte';
  import { websocket } from '../../../../stores';

  export let graphType = graphTypes.hour;
  dayjs.extend(utc);
  
  let graphData,
    dataKey = 'graphHour';

  const changeGraphType = type => () => {
    graphType = type;
    dataKey = `graph${graphType[0].toUpperCase()}${graphType.slice(1)}`;
  };

  const updateGraph = websocketData => {
    graphData = { ...initialGraphData };

    [graphData.datasets[0].data, graphData.labels] = (
      websocketData || []
    ).reduce(
      (tmp, data) => {
        const label =
          graphType === graphTypes.hour
            ? dayjs.utc(data.hour + '', 'YYYYMMDDHH').local().format('MM-DD HH:mm')
            : dayjs.utc(data.day + '', 'YYYYMMDD').local().format('YYYY-MM-DD');
        tmp[0].push(
          graphType === graphTypes.hour ? data.hash_1h : data.hash_1day,
        );
        tmp[1].push(label);
        return tmp;
      },
      [[], []],
    );
  };

  $: updateGraph($websocket.dashboard[dataKey]);
</script>

<div class="row">
  <DataCard title="Hashrate Chart">
    <div class="control-bar" slot="controls">
      <fieldset class="btn-group">
        <button
          on:click|preventDefault={changeGraphType(graphTypes.hour)}
          class:on={graphType === graphTypes.hour}
          class:off={graphType !== graphTypes.hour}>
          By Hour
        </button>
        <button
          on:click|preventDefault={changeGraphType(graphTypes.day)}
          class:on={graphType === graphTypes.day}
          class:off={graphType !== graphTypes.day}>
          By Day
        </button>
      </fieldset>
    </div>
    <div slot="body">
      {#if !$websocket.dashboard.loading}
        <div class="chart-container">
          <LineChart data={graphData} options={graphOptions} />
        </div>
      {:else}
        <Loading />
      {/if}
    </div>
  </DataCard>
</div>

<style>
  @media (min-width: 768px) {
    .chart-container {
      height: 300px;
    }
  }

  .chart-container {
    height: 550px;
  }</style>
