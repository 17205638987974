<script context="module">
  const toVoid = () => {};

  const defaultOptions = {
    onOpen: toVoid,
    onClose: toVoid,
    closeOnEsc: true,
    closeOnOuterClick: true,
    size: 'md',
  };

  const key = 'MINERIUM_MODAL';
</script>

<script>
  import { setContext } from 'svelte';

  let Component,
    props = {},
    options = {},
    opacity = !!Component ? 1 : 0,
    show = !!Component,
    timer,
    error;

  const fadeIn = () => {
    opacity += 0.5 / 30;
    if (opacity < 1) {
      timer = setTimeout(fadeIn, 0.5 / 30);
    }
  };

  const fadeOut = () => {
    opacity -= 0.5 / 30;
    if (opacity > 0) {
      timer = setTimeout(fadeOut, 0.5 / 30);
    } else {
      show = false;
    }
  };

  const open = (NewComponent, newProps = {}, newOptions = {}) => {
    Component = NewComponent;
    props = newProps;
    options = { ...defaultOptions, ...options, ...newOptions };
    options.onOpen();

    if (timer) {
      clearTimeout(timer);
    }
    show = true;
    fadeIn();
  };

  const close = data => {
    options.onClose(data);
    Component = null;
    props = {};
    options = {};

    if (timer) {
      clearTimeout(timer);
    }
    fadeOut();
  };

  const handleKeydown = event => {
    if (options.closeOnEsc && Component && event.key === 'Escape') {
      event.preventDefault();
      close();
    }

    if (Component && event.key === 'Tab') {
      // trap focus
      const nodes = modalWindow.querySelectorAll('*');
      const tabbable = Array.from(nodes).filter(node => node.tabIndex >= 0);

      let index = tabbable.indexOf(document.activeElement);
      if (index === -1 && event.shiftKey) index = 0;

      index += tabbable.length + (event.shiftKey ? -1 : 1);
      index %= tabbable.length;

      tabbable[index].focus();
      event.preventDefault();
    }
  };

  const handleOuterClick = event => {
    if (options.closeOnOuterClick) {
      // event.preventDefault();
      // close();
    }
  };

  setContext(key, { open, close });
</script>

<svelte:window on:keydown={handleKeydown} />
<div
  class="modal"
  class:show
  style={`opacity: ${opacity}`}
  tabindex="-1"
  role="dialog"
  on:click={handleOuterClick}>
  <div class="body">
    <div class="modal-dialog" role="document" on:click|stopPropagation={toVoid}>
      {#if error}
        <div class="server-error">
          <h3>{error.title || 'Server Connection Error'}</h3>
          <p>{error.message}</p>
        </div>
      {/if}
      <span class="modal-close-btn" on:click|preventDefault={close} />
      {#if props.title}
        <h2>{props.title}</h2>
      {/if}
      {#if Component}
        <svelte:component
          this={Component}
          {...props}
          on:error={e => (error = e.detail)} />
      {/if}
    </div>
  </div>
</div>
<slot />

<style>
  .modal.show {
    display: block;
  }</style>
