<script>
  import { getContext } from 'svelte';

  import Calendar from './Calendar.svelte';

  const { close } = getContext('MINERIUM_MODAL');

  const selectDate = day => {
    if (!day.sameMonth) {
      return;
    }

    close(day.date);
  };
</script>

<Calendar>
  <div class="actions" slot="header" let:date let:nextMonth let:prevMonth>
    <button on:click={prevMonth}>{'<'}</button>
    <h3>{date.format('YYYY-MMMM')}</h3>
    <button on:click={nextMonth}>{'>'}</button>
  </div>
  <div class="week" slot="weekdays" let:days>
    {#each days as day (day.i)}<span>{day.date.format('ddd')}</span>{/each}
  </div>
  <div slot="days" let:weeks>
    {#each weeks as week}
      <div class="week">
        {#each week as day (day.i)}
          <span
            class:same-month={day.sameMonth}
            on:click={selectDate(day)}>{day.date.format('DD')}</span>
        {/each}
      </div>
    {/each}
  </div>
</Calendar>

<style>
  .actions {
    display: grid;
    grid-template-columns: 20% 1fr 20%;
    margin-bottom: 10px;
  }

  .actions h3 {
    padding-top: 5px;
    margin: 0;
    text-align: center;
    user-select: none;
  }

  .week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .week span {
    color: #999;
    text-align: center;
    user-select: none;
  }

  .week span.same-month {
    color: #333;
    cursor: pointer;
  }</style>
