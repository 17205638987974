<script context="module">
  const createPagination = (workers, page, pageSize, pageAroundSize) => {
    const pages = [];
    let addedBefore = false,
      addedAfter = false;
    for (let i = 0; i < workers.length; i += pageSize) {
      if (
        i === 0 ||
        i === workers.length - 1 ||
        (i > page * pageSize - pageSize * (pageAroundSize + 1) &&
          i < page * pageSize + pageSize * (pageAroundSize + 1))
      ) {
        pages.push(i / pageSize);
      } else if (
        !addedBefore &&
        i > 0 &&
        i < page * pageSize - pageSize * (pageAroundSize + 1)
      ) {
        pages.push('...');
        addedBefore = true;
      } else if (
        !addedAfter &&
        i < workers.length - 1 &&
        i > page * pageSize + pageSize * (pageAroundSize + 1)
      ) {
        pages.push('...');
        addedAfter = true;
      }
    }

    return pages;
  };
</script>

<script>
  import { createEventDispatcher, getContext, onMount } from 'svelte';
  import dayjs from 'dayjs';

  import { ChevronDown, ChevronUp } from './icons';
  import CreateWorkerGroup from './CreateWorkerGroup.svelte';
  import CreateWatcherLink from './CreateWatcherLink.svelte';
  import Table from './Table.svelte';
  import ThSorted from './ThSorted.svelte';
  import { user } from '../stores';

  export let fields;
  export let workers;
  export let unfilteredWorkers;
  export let page;
  export let activeSort;
  export let activeFilter;

  const dispatch = createEventDispatcher();
  const { open } = getContext('MINERIUM_MODAL');

  let pages = [],
    pageSize = 10,
    pageAroundSize = 2,
    displayWorkers = [],
    checkedWorkers = {},
    hasSelectedWorkers = false;

  const updateDisplay = (workers, page, pageSize) => {
    pages = createPagination(workers, page, pageSize, pageAroundSize);
    displayWorkers = workers.slice(page * pageSize, page * pageSize + pageSize);
    dispatch('updated', displayWorkers);
  };

  const updatePage = requestedPage => () => {
    if (requestedPage !== page) {
      dispatch('update', { page: requestedPage });
    }
  };

  const updateFilter = filter => () => {
    if (filter !== activeFilter) {
      dispatch('update', { filter });
    }
  };

  const createGroup = () => {
    open(
      CreateWorkerGroup,
      {
        title: 'Create new group',
        workers: checkedWorkers,
      },
      {
        onClose: group => {
          if (!group) {
            return;
          }
          dispatch('update', { fetchGroups: true, activateGroup: group });
        },
      },
    );
  };

  const createWatcher = () => {
    if (Object.keys(checkedWorkers).length > 0) {
      open(
        CreateWorkerGroup,
        {
          title: 'Create new group',
          workers: checkedWorkers,
        },
        {
          onClose: group => {
            if (!group) {
              return;
            }

            setTimeout(() => {
              dispatch('update', {
                fetchGroups: true,
                activateGroup: group,
              });
              open(CreateWatcherLink, {
                title: 'Create new watcher link',
                workerGroupId: group.id,
              });
            }, 200);
          },
        },
      );
      return;
    }
    open(CreateWatcherLink, { title: 'Create new watcher link' });
  };

  const handleMultiSelect = (index, key) => ({ shiftKey }) => {
    // TODO: Add multi select support
  };

  onMount(() => {
    updateDisplay(workers, page, pageSize, activeSort, activeFilter);
  });

  $: updateDisplay(workers, page, pageSize, activeSort, activeFilter);

  let prevFilter = activeFilter;
  let prevSort = activeSort;
  $: {
    if (prevFilter !== activeFilter || prevSort !== activeSort) {
      checkedWorkers = {};
    }
  }
</script>

<svelte:options immutable />
<div class="control-bar">
  <fieldset class="btn-group">
    {#if unfilteredWorkers.length > 0 && $user && $user.displayName && $user.subaccounts[0].id === unfilteredWorkers[0].uid}
    <button class="on" on:click|preventDefault={createGroup}>
      Create Group
    </button>
    <button class="on" on:click|preventDefault={createWatcher}>
      Create Watcher
    </button>
    {/if}
    <slot></slot>
  </fieldset>
  {#if unfilteredWorkers.length > 0}
    <fieldset class="btn-group">
      <button
        class:off={activeFilter !== 'all'}
        on:click|preventDefault={updateFilter('all')}>
        All
        <i>{unfilteredWorkers.length}</i>
      </button>
      <button
        class:off={activeFilter !== 'online'}
        on:click|preventDefault={updateFilter('online')}>
        Online
        <i>
          {unfilteredWorkers.filter(worker => parseInt(worker.hash1m) > 0).length}
        </i>
      </button>
      <button
        class:off={activeFilter !== 'offline'}
        on:click|preventDefault={updateFilter('offline')}>
        Offline
        <i>
          {unfilteredWorkers.filter(worker => parseInt(worker.hash1m) === 0).length}
        </i>
      </button>
      <button
        class:off={activeFilter !== 'inactive'}
        on:click|preventDefault={updateFilter('inactive')}>
        Inactive
        <i>
          {unfilteredWorkers.filter(worker => parseInt(worker.hash1d) === 0).length}
        </i>
      </button>
    </fieldset>
    {/if}
  </div>
<div class="body">
  <Table
    items={displayWorkers}
    key="worker_name"
    colCount={7}
    on:requestSort={e => dispatch('update', {
        sort: { key: e.detail[0], order: e.detail[1] },
      })}>
    <tr slot="thead" let:sortColumn let:direction>
      <th />
      {#each fields as field (field.key)}
        <ThSorted {sortColumn} {direction} key={fields.key}>
          {field.label}
        </ThSorted>
      {/each}
    </tr>
    <tr slot="tbody" let:item let:index>
      <td>
        <div class="checkbox">
          <input
            type="checkbox"
            id={`checkbox-${item.worker_name}`}
            bind:checked={checkedWorkers[item.worker_name]}
            on:click={handleMultiSelect(index, item.worker_name)} />
          <label for={`checkbox-${item.worker_name}`} />
        </div>
      </td>
      {#each fields as field (field.key)}
        {#if field.key.startsWith('hash')}
          <td>
            {`${item[field.key]} ${item[field.key] > 1000 ? 'PH/s' : 'TH/s'}`}
          </td>
        {:else if field.key !== 'lastupdate'}
          <td>{item[field.key]}</td>
        {:else}
          <td>{dayjs(item.lastupdate * 1000).format('YYYY-MM-DD HH:mm')}</td>
        {/if}
      {/each}
    </tr>
  </Table>
  {#if pages.length > 1}
    <div class="pagination">
      {#each pages as pageNumber}
        {#if pageNumber === '...'}
          <button disabled>...</button>
        {:else}
          <button
            class:on={pageNumber === page}
            on:click|preventDefault={updatePage(pageNumber)}>
            {pageNumber + 1}
          </button>
        {/if}
      {/each}
    </div>
  {/if}
</div>

<style>
  th {
    cursor: pointer;
  }

  th :global(svg) {
    margin-left: 5px;
  }</style>
