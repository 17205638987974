<script context="module">
  import { coinColors } from '../../../../utils/color';

  const options = {
    layout: {
      labelString: ' TH/s',
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 20,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: value => value + ' TH/s',
            min: 0,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || '';

          if (label) {
            label +=
              ': ' +
              (tooltipItem.yLabel > 1000
                ? (tooltipItem.yLabel / 1000).toFixed(2)
                : tooltipItem.yLabel) +
              (tooltipItem.yLabel > 1000 ? ' PH/s' : ' TH/s');
          }
          return label;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: value => value + ' TH/s',
            min: 0,
          },
        },
      ],
    },
  };

  const getDatasets = (graphType, data, labels) => {
    const rgb = coinColors.all;

    return {
      label: 'Hashrate',
      fill: true,
      lineTension: 0.1,
      backgroundColor: `rgba(${rgb.join(',')},1)`,
      borderColor: `rgba(${rgb.join(',')},1)`,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: `rgba(${rgb.join(',')},1)`,
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: `rgba(${rgb.join(',')},1)`,
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 20,
      data: labels.map(label => {
        const item = data.find(
          item =>
            label ===
            dayjs.utc(item[graphType].toString(), 'YYYYMMDDHH').local().format(
              'MM-DD HH:30',
            ),
        );

        if (
          item &&
          (typeof item.hash1hr !== 'undefined' ||
            typeof item.hash1d !== 'undefined')
        ) {
          return 0;
        }

        return item
          ? Object.values(item.coins).reduce((tmp, hash) => tmp + hash, 0)
          : 0;
      }),
    };
  };

  const getDataset = (coin, graphType, data, labels) => {
    const rgb = coinColors[coin.toLowerCase()];

    return {
      label: 'Hashrate',
      fill: true,
      lineTension: 0.1,
      backgroundColor: `rgba(${rgb.join(',')},1)`,
      borderColor: `rgba(${rgb.join(',')},1)`,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: `rgba(${rgb.join(',')},1)`,
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: `rgba(${rgb.join(',')},1)`,
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 20,
      data: labels.map(label => {
        const item = data.find(
          item =>
            label ===
            dayjs.utc(item[graphType].toString(), 'YYYYMMDDHH').local().format(
              'MM-DD HH:30',
            ),
        );
        return item ? item[graphType === 'hour' ? 'hash1hr' : 'hash_1day'] : 0;
      }),
    };
  };
</script>

<script>
  import { params } from '@sveltech/routify';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import BarChart from 'svelte-chartjs/src/Bar.svelte';

  import DataCard from '../../../../components/DataCard.svelte';
  import Loading from '../../../../components/Loading.svelte';
  import { websocket } from '../../../../stores';

  export let graphType = 'hour';

  let chartData;
  dayjs.extend(utc);
  
  const handleGraphData = async websocketData => {
    if (!websocketData[`${graphType}Stats`]) {
      return;
    }

    const labels = websocketData[`${graphType}Stats`]
      .sort((a, b) => {
        const keyA = a[graphType];
        const keyB = b[graphType];
        if (keyA < keyB) {
          return -1;
        } else if (keyA > keyB) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(item =>
        dayjs.utc(item[graphType].toString(), 'YYYYMMDDHH').local().format('MM-DD HH:30'),
      );

    chartData = {
      labels,
      datasets:
        $params.coin.toLowerCase() === 'all'
          ? [getDatasets(graphType, websocketData[`${graphType}Stats`], labels)]
          : [
              getDataset(
                $params.coin,
                graphType,
                websocketData[`${graphType}Stats`],
                labels,
              ),
            ],
    };
  };

  const changeGraphType = type => () => {
    graphType = type;
  };

  $: handleGraphData($websocket.pool);
</script>

<div class="row">
  <DataCard title="Hashrate Chart">
    <div class="control-bar" slot="controls">
      <fieldset class="btn-group">
        <button
          on:click|preventDefault={changeGraphType('hour')}
          class:off={graphType !== 'hour'}
          class:on={graphType === 'hour'}>By Hour</button>
        <button
          on:click|preventDefault={changeGraphType('day')}
          class:off={graphType !== 'day'}
          class:on={graphType === 'day'}>By Day</button>
      </fieldset>
    </div>
    <div slot="body">
      {#if !$websocket.pool.loading}
        <div class="chart-container">
          <BarChart data={chartData} {options} />
        </div>
      {:else}
        <Loading />
      {/if}
    </div>
  </DataCard>
</div>

<style>
  @media (min-width: 768px) {
    .chart-container {
      height: 400px;
    }
  }

  .chart-container {
    height: 550px;
  }</style>
