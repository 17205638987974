<script>
  import { toast } from '@zerodevx/svelte-toast';
  import user from '../stores/user';

  export let eventMessage = '';
  export let noWorkers = true;
  export let modal = {
    visible: false,
    slides: [true, false, false, false],
  };
  const copy = poolUrl => {
    navigator.clipboard.writeText(poolUrl);
    toast.push('Copied to clipboard.');
  };
  export const modalHandler = m => {
    let index = modal.slides.findIndex(e => e);
    console.log(eventMessage);
    switch (m) {
      case 'NEXT':
        if (modal.slides[3]) {
          break;
        }
        modal.slides[index] = false;
        modal.slides[index + 1] = true;
        break;

      case 'PREV':
        if (modal.slides[0]) {
          break;
        }
        modal.slides[index] = false;
        modal.slides[index - 1] = true;
        break;

      case 'CLOSE':
        modal.visible = false;
        modal.slides = [true, false, false, false];

        break;

      case 'OPEN':
        modal.visible = true;
        break;
    }
    eventMessage = 'Done';
  };
  export const open = (showNoWorkers = true) => {
    noWorkers = showNoWorkers;
    modalHandler('OPEN');
  }
    $: modalHandler(eventMessage);
</script>

<!-- TODO: add onclick to transparent background to close the modal -->
<div
  class="modal"
  id="modal-help"
  class:visible={modal.visible}
  class:hidden={!modal.visible}>
  <div class="body">
    <span on:click={() => (eventMessage = 'CLOSE')} class="modal-close-btn" />

    <!--//////////////////////-->
    <div class="help-slider">
      <div class:visible={noWorkers} class:hidden={!noWorkers} class="static-header">
        <h3>No Worker Configured!</h3>
        <p>Please follow these steps to configure your workers:</p>
      </div>
      <div class:visible={!noWorkers} class:hidden={noWorkers} class="static-header">
        <h3>Adding More Workers!</h3>
        <p>Please follow these steps to configure your new workers:</p>
      </div>
      <div class="splide__arrows">
        <button
          on:click={() => (eventMessage = 'PREV')}
          class="splide__arrow splide__arrow--prev">
          Prev</button>
        <button
          class:hidden={modal.slides[3]}
          on:click={() => (eventMessage = 'NEXT')}
          class="splide__arrow splide__arrow--next">
          Next</button>
        <button
          class:hidden={!modal.slides[3]}
          on:click={() => (eventMessage = 'CLOSE')}
          class="splide__arrow splide__arrow--next">
          Finish</button>
      </div>

      <div class="splide__track">
        <div class="splide__list">
          <div
            class="splide__slide"
            class:visible={modal.slides[0]}
            class:hidden={!modal.slides[0]}>
            <div class="slide__contents">
              <h4>1. Connect to The Miner</h4>
              <ul class="bluedot">
                <li>
                  Connect your computer to the same router or switch that your
                  miner is connected to.
                </li>
                <li>Get the IP address of the miner.</li>
                <li>Using your browser, log in to the miner.</li>
              </ul>
            </div>
          </div>

          <div
            class="splide__slide"
            class:visible={modal.slides[1]}
            class:hidden={!modal.slides[1]}>
            <div class="slide__contents">
              <h4>2. Config the Miner & Connect to Pool</h4>
              <ul class="bluedot">
                <li>
                  Set the pool URL to Minerium addresses listed below this page.
                </li>
                <li>Set the worker to {$user && $user.username || "username"}.workerID.</li>
                <li>
                  Set the password to any content you choose; just don’t leave
                  it blank.
                </li>
              </ul>
              <fieldset class="btn-attached">
                <input
                  type="text"
                  value="stratum+tcp://stratum.minerium.com:3333"
                  readonly />
                <button
                  on:click={() => copy('stratum+tcp://stratum.minerium.com:3333')}>COPY</button>
              </fieldset>
              <fieldset class="btn-attached">
                <input
                  type="text"
                  value="stratum+tcp://stratum.minerium.com:4444"
                  readonly />
                <button
                  on:click={() => copy('stratum+tcp://stratum.minerium.com:4444')}>COPY</button>
              </fieldset>
              <fieldset class="btn-attached">
                <input
                  type="text"
                  value="stratum+tcp://stratum.minerium.com:44443"
                  readonly />
                <button
                  on:click={() => copy('stratum+tcp://stratum.minerium.com:44443')}>COPY</button>
              </fieldset>
            </div>
          </div>

          <!--//////////////////////-->
          <div
            class="splide__slide"
            class:visible={modal.slides[2]}
            class:hidden={!modal.slides[2]}>
            <div class="slide__contents">
              <h4>3. An Example</h4>
              <p>
                A miner with the mining account username
                <b>{$user && $user.username || "username"}</b>
                and a worker named
                <b>worker001</b>
                who wants to connect to our pool server would configure his/her
                device as follows:
              </p>
              <p>
                <b>URL:</b>
                stratum+tcp://pool.minerium,com:3333<br />
                <b>Username:</b>
                {$user && $user.username || "username"}.worker001<br />
                <b>Password:</b>
                123<br />
              </p>
              <p>Please note, each miner requires its own ID.</p>
            </div>
          </div>

          <!--//////////////////////-->
          <div
            class="splide__slide"
            class:visible={modal.slides[3]}
            class:hidden={!modal.slides[3]}>
            <div class="slide__contents">
              <h4>4. Enjoy Mining</h4>
              <p>
                Once the miner is connected to the Minerium pool, you can
                confirm its status to view data in the background page,
                including miner uptime, hashrate status and mining revenue.
              </p>
              <p>Having a problem? Check our FAQ page to find more.</p>
              <!--              <button><a href="https://minerium.com/faqs">Minerium FAQ page</a></button>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .visible {
    display: block;
  }
  .hidden {
    display: none;
  }</style>
